import React from 'react';
import { notifySuccess, warningConfirmModal } from '@citrite/citrix-ui';
import { SessionsEntity, User } from '@citrite/sf-api';
import { IAction } from '../Actions/IAction';
import { t } from '../utils/translate';

export function LogUserOut(props: IAction) {
	if (!props.show) {
		return null;
	}

	const logUserOut = (user: User) => {
		return warningConfirmModal({
			title: t('user-actions:account_access.log_user_out'),
			message: t('user-actions:account_access.log_user_out_message'),
			confirmButtonText: t('user-actions:account_access.log_user_out_button'),
			onCloseSuccess: () =>
				SessionsEntity.deleteByUser(user?.url || '')
					.execute()
					.then(() =>
						notifySuccess(
							t('user-actions:account_access.logged_out_successfully', {
								name: user.FullName,
							})
						)
					),
		});
	};

	return (
		<div
			data-pendo-id="LogUserOutActionsPilet"
			className="linkStyle"
			onClick={() => logUserOut(props.managedUser)}
		>
			{t('user-actions:account_access.log_user_out')}
		</div>
	);
}
