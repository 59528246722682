import React from 'react';
import { notifySuccess, warningConfirmModal } from '@citrite/citrix-ui';
import { SessionsEntity, UserRole, UsersEntity } from '@citrite/sf-api';
import { useHistory } from 'react-router';
import { IActionWithRoute } from '../Actions/IAction';
import { t } from '../utils';

export const DisableUser = (props: IActionWithRoute) => {
	const history = useHistory();

	if (!props.show) {
		return null;
	}

	const goBack = () => {
		if (props.managedUser.Roles?.includes(UserRole.Employee)) {
			history.push(props.employeesRoute);
		} else {
			history.push(props.clientsRoute);
		}
	};

	const toggleDisabled = () => {
		const user = props.managedUser;

		if (user.Security?.IsDisabled) {
			return UsersEntity.update(user.url || '', { Security: { IsDisabled: false } })
				.execute()
				.then(() => {
					goBack();
					notifySuccess(
						t('user-actions:account_access.user_enabled_successfully', {
							name: user.FullName,
						})
					);
				});
		}

		warningConfirmModal({
			title: t('user-actions:account_access.disable_user'),
			message: t('user-actions:account_access.disable_user_message'),
			confirmButtonText: t('user-actions:account_access.disable_button'),
			onCloseSuccess: () =>
				UsersEntity.update(user.url || '', { Security: { IsDisabled: true } })
					.execute()
					.then(() => {
						SessionsEntity.deleteByUser(user.url || '').execute();
						goBack();
						notifySuccess(
							t('user-actions:account_access.user_disabled_successfully', {
								name: user.FullName,
							})
						);
					}),
		});
	};

	return (
		<span
			data-pendo-id="DisableUserActionsPilet"
			className="linkStyle"
			onClick={toggleDisabled}
			data-testid="disableEnableUser"
		>
			{props.managedUser.Security?.IsDisabled
				? t('user-actions:account_access.enable_user')
				: t('user-actions:account_access.disable_user')}
		</span>
	);
};
