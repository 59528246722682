import React from 'react';
import { UserRole } from '@citrite/sf-api';
import { cx } from '@emotion/css';
import { ActionsProps } from '../Actions/Actions';
import { actionStyles } from '../Actions/styled';
import { t } from '../utils/translate';
import { DisableUser } from './DisableUser';
import { LogUserOut } from './LogUserOut';
import { ResetPassword } from './ResetPassword';
import { ResetTwoStepVerification } from './ResetTwoStepVerification';

export const AccountAccessActions = (props: ActionsProps) => {
	const canManageEmployees = props.currentUser.Roles?.includes(
		UserRole.AdminManageEmployees
	);
	const isCurrentUser = props.currentUser.Id === props.managedUser.Id;
	const isCurrentUserMasterAdmin = props.currentUser.Roles?.includes(
		UserRole.MasterAdmin
	);

	return (
		<div className={cx(actionStyles)} data-testid="accountAccessActions">
			<div className="sectionHeader">{t('user-actions:account_access.header')}</div>
			<div className="sectionDescription">
				{t('user-actions:account_access.description')}
			</div>
			<DisableUser
				managedUser={props.managedUser}
				show={canManageEmployees}
				clientsRoute={props.clientsRoute}
				employeesRoute={props.employeesRoute}
			/>
			<LogUserOut
				managedUser={props.managedUser}
				show={!isCurrentUser && props.accountPreferences.EnableRemoteWipe}
			/>
			<ResetTwoStepVerification
				managedUser={props.managedUser}
				show={isCurrentUserMasterAdmin && props.accountPreferences.EnableTwoFactorAuth}
			/>
			<ResetPassword
				managedUser={props.managedUser}
				currentUser={props.currentUser}
				currentUserHasAdminRole={props.currentUserHasAdminRole}
				show={true}
			/>
		</div>
	);
};
