/* tslint:disable */
/* This file was generated by @citrite/translate. Manual changes will be lost. */
import { Language } from '@citrite/translate';
export type locale =
	| 'de'
	| 'en'
	| 'es'
	| 'fr'
	| 'it'
	| 'ja'
	| 'ko'
	| 'nl'
	| 'pt-BR'
	| 'ru'
	| 'zh-CN';
export const languages: Language[] = [
	{
		key: 'de',
		content: {
			account_access: {
				description:
					'Verwalten Sie den Zugriff dieses Benutzers, wenn Sie glauben, dass sein Konto kompromittiert wurde',
				disable_button: 'Deaktivieren',
				disable_user: 'Zugriff deaktivieren',
				disable_user_message: 'Möchten Sie diesen Benutzer wirklich deaktivieren?',
				email_password: 'E-Mail zum Zurücksetzen des Kennworts senden',
				email_password_link_text: 'Kennwort zurücksetzen',
				email_password_message:
					'Dadurch wird eine E-Mail mit einem Link zum Zurücksetzen des Kennworts an {{email}} gesendet. Der Link läuft in 30 Minuten ab.',
				email_sent_toast:
					'E-Mail zum Zurücksetzen des Kennworts wurde an {{email}} gesendet',
				enable_user: 'Zugriff aktivieren',
				header: 'Kontozugriff',
				log_user_out: 'Diesen Benutzer abmelden',
				log_user_out_button: 'Abmelden',
				log_user_out_message: 'Möchten Sie diesen Benutzer wirklich abmelden?',
				logged_out_successfully: '{{name}} wurde abgemeldet',
				reset: 'Zurücksetzen',
				reset_two_step_verification: 'Zweistufige Verifizierung zurücksetzen',
				reset_two_step_verification_error:
					'Die zweistufige Verifizierung kann für {{name}} nicht zurückgesetzt werden',
				reset_two_step_verification_message:
					'Möchten Sie die zweistufige Verifizierung für diesen Benutzer wirklich zurücksetzen? Dadurch wird dem Benutzer eine E-Mail gesendet und er muss seine Telefonnummer zurücksetzen, bevor er sich anmelden kann.',
				reset_two_step_verification_success:
					'Die zweistufige Verifizierung wurde für {{name}} zurückgesetzt',
				reset_two_step_verification_title: 'Zweistufige Verifizierung zurücksetzen',
				user_disabled_successfully: '{{name}} wurde deaktiviert',
				user_enabled_successfully: '{{name}} wurde aktiviert',
			},
			folder_access: {
				delete_button: 'Löschen',
				delete_from_all_folders: 'Aus allen Ordnern entfernen',
				delete_from_folders_successfully: '{{name}} aus Ordnern gelöscht',
				delete_from_my_folders: 'Aus Ihren Ordnern entfernen',
				delete_from_my_folders_message:
					'Das Löschen des Benutzers aus den Ordnern kann nicht rückgängig gemacht werden.',
				description:
					'Den Zugriff dieses Benutzers auf vertrauliche Inhalte in Ordnern einschränken',
				header: 'Ordnerzugriff',
			},
		},
	},
	{
		key: 'en',
		content: {
			account_access: {
				description:
					"Manage this user's access if you think their account might have been compromised",
				disable_button: 'Disable',
				disable_user: 'Disable access',
				disable_user_message: 'Are you sure you want to disable this user?',
				email_password: 'Send Password Reset Email',
				email_password_link_text: 'Reset password',
				email_password_message:
					'This will send an e-mail to {{email}} with a link to reset their password. The link will expire in 30 minutes.',
				email_sent_toast: 'Reset password e-mail was sent successfully to {{email}}',
				enable_user: 'Enable access',
				header: 'Account access',
				log_user_out: 'Log this user out',
				log_user_out_button: 'Log Out',
				log_user_out_message: 'Are you sure you want to log this user out?',
				logged_out_successfully: '{{name}} logged out successfully',
				reset: 'Reset',
				reset_two_step_verification: 'Reset two-step verification',
				reset_two_step_verification_error:
					'Unable to reset two-step verification for {{name}}',
				reset_two_step_verification_message:
					"Are you sure you want to reset this user's two-step verification? This will send an email to the user and require them to reset their phone number before they can sign in.",
				reset_two_step_verification_success:
					'Two-step verification was reset for {{name}}',
				reset_two_step_verification_title: 'Reset Two-step verification',
				user_disabled_successfully: '{{name}} disabled successfully',
				user_enabled_successfully: '{{name}} enabled successfully',
			},
			folder_access: {
				delete_button: 'Delete',
				delete_from_all_folders: 'Remove from all folders',
				delete_from_folders_successfully: '{{name}} deleted from folders successfully',
				delete_from_my_folders: 'Remove from your folders',
				delete_from_my_folders_message:
					'If you delete this user from the folders, the process cannot be reversed.',
				description: "Limit this user's access to sensitive content in folders",
				header: 'Folder access',
			},
		},
	},
	{
		key: 'es',
		content: {
			account_access: {
				description:
					'Administre el acceso de este usuario si cree que la seguridad de su cuenta podría estar en peligro',
				disable_button: 'Inhabilitar',
				disable_user: 'Inhabilitar el acceso',
				disable_user_message: '¿Seguro que quiere inhabilitar a este usuario?',
				email_password: 'Enviar correo de restablecimiento de contraseña',
				email_password_link_text: 'Restablecer contraseña',
				email_password_message:
					'Esto enviará un correo electrónico a {{email}} con un enlace para restablecer su contraseña. El enlace caducará en 30 minutos.',
				email_sent_toast:
					'El correo electrónico de restablecimiento de contraseña se envió correctamente a {{email}}',
				enable_user: 'Habilitar el acceso',
				header: 'Acceso a la cuenta',
				log_user_out: 'Cerrar la sesión de este usuario',
				log_user_out_button: 'Cerrar sesión',
				log_user_out_message: '¿Seguro que quiere cerrar la sesión de este usuario?',
				logged_out_successfully: 'La sesión de {{name}} se cerró correctamente',
				reset: 'Restablecer',
				reset_two_step_verification: 'Restablecer verificación de dos pasos',
				reset_two_step_verification_error:
					'No se puede restablecer la verificación de dos pasos para {{name}}',
				reset_two_step_verification_message:
					'¿Seguro que quiere restablecer la verificación de dos pasos para este usuario? Se enviará un correo electrónico al usuario y se le pedirá que restablezca su número de teléfono antes de que pueda iniciar sesión.',
				reset_two_step_verification_success:
					'La verificación de dos pasos se ha restablecido para {{name}}',
				reset_two_step_verification_title: 'Restablecer verificación de dos pasos',
				user_disabled_successfully: '{{name}} inhabilitado correctamente',
				user_enabled_successfully: '{{name}} habilitado correctamente',
			},
			folder_access: {
				delete_button: 'Eliminar',
				delete_from_all_folders: 'Quitar de todas las carpetas',
				delete_from_folders_successfully:
					'{{name}} eliminado correctamente de las carpetas',
				delete_from_my_folders: 'Quitar de sus carpetas',
				delete_from_my_folders_message:
					'Si elimina a este usuario de las carpetas, este proceso no se puede revertir.',
				description:
					'Limitar el acceso de este usuario al contenido confidencial de las carpetas',
				header: 'Acceso a carpetas',
			},
		},
	},
	{
		key: 'fr',
		content: {
			account_access: {
				description:
					'Gérez l’accès de cet utilisateur si vous pensez que son compte a été compromis',
				disable_button: 'Désactiver',
				disable_user: "Désactiver l'accès",
				disable_user_message: 'Êtes-vous sûr de vouloir désactiver cet utilisateur ?',
				email_password: 'Envoyer e-mail de réinitialisation du mot de passe',
				email_password_link_text: 'Réinitialiser le mot de passe',
				email_password_message:
					'Un lien de réinitialisation du mot de passe sera envoyé par e-mail à {{email}}. Ce lien expire dans 30 minutes.',
				email_sent_toast:
					"L'e-mail de réinitialisation du mot de passe a été envoyé avec succès à {{email}}",
				enable_user: "Activer l'accès",
				header: 'Accès au compte',
				log_user_out: 'Déconnecter cet utilisateur',
				log_user_out_button: 'Fermer la session',
				log_user_out_message: 'Êtes-vous sûr de vouloir déconnecter cet utilisateur ?',
				logged_out_successfully: "{{name}} s'est déconnecté avec succès",
				reset: 'Réinitialiser',
				reset_two_step_verification: 'Réinitialiser la validation en deux étapes',
				reset_two_step_verification_error:
					'Impossible de réinitialiser la validation en deux étapes pour {{name}}',
				reset_two_step_verification_message:
					"Êtes-vous sûr de vouloir réinitialiser la validation en deux étapes de cet utilisateur ? Cela enverra un e-mail à l'utilisateur et lui demandera de réinitialiser son numéro de téléphone avant de pouvoir se connecter.",
				reset_two_step_verification_success:
					'La validation en deux étapes a été réinitialisée pour {{name}}',
				reset_two_step_verification_title: 'Réinitialiser la validation en deux étapes',
				user_disabled_successfully: '{{name}} désactivé avec succès',
				user_enabled_successfully: '{{name}} activé avec succès',
			},
			folder_access: {
				delete_button: 'Supprimer',
				delete_from_all_folders: 'Supprimer de tous les dossiers',
				delete_from_folders_successfully: '{{name}} supprimé des dossiers avec succès',
				delete_from_my_folders: 'Supprimer de vos dossiers',
				delete_from_my_folders_message:
					'Si vous supprimez cet utilisateur des dossiers, le processus ne peut pas être inversé.',
				description:
					"Limiter l'accès de cet utilisateur au contenu sensible des dossiers",
				header: 'Accès aux dossiers',
			},
		},
	},
	{
		key: 'it',
		content: {
			account_access: {
				description:
					"Gestire l'accesso di questo utente se si ritiene che il suo account possa essere stato compromesso",
				disable_button: 'Disabilita',
				disable_user: "Disabilita l'accesso",
				disable_user_message: "Disabilitare l'utente?",
				email_password: 'Invia e-mail di reimpostazione password',
				email_password_link_text: 'Reimposta la password',
				email_password_message:
					"Verrà inviata un'e-mail a {{email}} con un collegamento per il ripristino della password. Il collegamento scadrà tra 30 minuti.",
				email_sent_toast:
					'E-mail di ripristino della password inviata correttamente a {{email}}',
				enable_user: "Abilita l'accesso",
				header: "Accesso all'account",
				log_user_out: "Scollega l'utente",
				log_user_out_button: 'Scollega',
				log_user_out_message: "Scollegare l'utente?",
				logged_out_successfully: '{{name}} scollegato correttamente',
				reset: 'Reimposta',
				reset_two_step_verification: 'Reimposta verifica in due passaggi',
				reset_two_step_verification_error:
					'Impossibile reimpostare la verifica in due passaggi per {{name}}',
				reset_two_step_verification_message:
					"Reimpostare la verifica in due passaggi di questo utente? Facendolo, sarà inviata un'e-mail all'utente che richiede loro di reimpostare il proprio numero di telefono prima di poter accedere.",
				reset_two_step_verification_success:
					'La verifica in due passaggi è stata reimpostata per {{name}}',
				reset_two_step_verification_title: 'Reimposta verifica in due passaggi',
				user_disabled_successfully: '{{name}} disabilitato correttamente',
				user_enabled_successfully: '{{name}} abilitato correttamente',
			},
			folder_access: {
				delete_button: 'Elimina',
				delete_from_all_folders: 'Rimuovi da tutte le cartelle',
				delete_from_folders_successfully:
					'{{name}} eliminato dalle cartelle correttamente',
				delete_from_my_folders: 'Rimuovi dalle tue cartelle',
				delete_from_my_folders_message:
					"Se l'utente viene eliminato dalle cartelle, l'operazione sarà irreversibile.",
				description:
					"Limita l'accesso di questo utente ai contenuti sensibili nelle cartelle",
				header: 'Accesso alle cartelle',
			},
		},
	},
	{
		key: 'ja',
		content: {
			account_access: {
				description:
					'このユーザーのアカウントが侵害された可能性がある場合は、このユーザーのアクセスを管理します',
				disable_button: '無効',
				disable_user: 'アクセスを無効にする',
				disable_user_message: 'このユーザーを無効にしてもよろしいですか?',
				email_password: 'パスワード リセット メールの送信',
				email_password_link_text: 'パスワードをリセットする',
				email_password_message:
					'これによって、ユーザーのパスワードをリセットするためのリンクを記載したメールを {{email}} に送信します。',
				email_sent_toast: 'パスワードのリセットメールが{{email}}に送信されました',
				enable_user: 'アクセスを有効にする',
				header: 'アカウントのアクセス',
				log_user_out: 'このユーザーをログアウトします',
				log_user_out_button: 'ログアウト',
				log_user_out_message: 'このユーザーをログアウトしてもよろしいですか?',
				logged_out_successfully: '{{name}}はログアウトしました',
				reset: 'リセット',
				reset_two_step_verification: '2 段階認証をリセットする',
				reset_two_step_verification_error: '{{name}} の 2 段階認証をリセットできません',
				reset_two_step_verification_message:
					'このユーザーの 2 段階認証をリセットしてもよろしいですか? これにより、ユーザーにメールが送信され、サイン インする前に電話番号をリセットする必要があります。',
				reset_two_step_verification_success: '{{name}} の 2 段階認証がリセットされました',
				reset_two_step_verification_title: '2 段階認証をリセットする',
				user_disabled_successfully: '{{name}}が無効になりました',
				user_enabled_successfully: '{{name}}が有効になりました',
			},
			folder_access: {
				delete_button: '削除',
				delete_from_all_folders: 'すべてのフォルダーから削除する',
				delete_from_folders_successfully: '{{name}}をフォルダーから削除しました',
				delete_from_my_folders: '自分のフォルダーから削除する',
				delete_from_my_folders_message:
					'このユーザーをフォルダーから削除すると、処理は元に戻せません。',
				description:
					'このユーザーによるフォルダー内の機密コンテンツへのアクセスを制限する',
				header: 'フォルダー アクセス',
			},
		},
	},
	{
		key: 'ko',
		content: {
			account_access: {
				description:
					'사용자 계정이 도용되었다고 생각되면 이 사용자의 액세스 권한을 관리하십시오',
				disable_button: '사용 안 함',
				disable_user: '액세스 권한 비활성화',
				disable_user_message: '이 사용자를 사용하지 않도록 설정하시겠습니까?',
				email_password: '암호 다시 설정 전자 메일 전송',
				email_password_link_text: '암호 재설정',
				email_password_message:
					'암호를 다시 설정할 수 있는 링크가 포함된 전자 메일이 {{email}} 주소로 전송됩니다. 링크는 30분 후에 만료됩니다.',
				email_sent_toast: '암호 재설정 전자 메일을 {{email}}에 보냈습니다.',
				enable_user: '액세스 권한 활성화',
				header: '계정 액세스',
				log_user_out: '이 사용자를 로그아웃 처리',
				log_user_out_button: '로그아웃',
				log_user_out_message: '이 사용자를 로그아웃 처리하시겠습니까?',
				logged_out_successfully: '{{name}}이(가) 로그아웃되었습니다.',
				reset: '재설정',
				reset_two_step_verification: '2단계 인증 재설정',
				reset_two_step_verification_error:
					'{{name}}의 2단계 인증을 재설정할 수 없습니다.',
				reset_two_step_verification_message:
					'이 사용자의 2단계 인증을 재설정하시겠습니까? 그러면 사용자에게 전자 메일이 전송되고 해당 사용자는 로그인하기 전에 전화 번호를 재설정해야 합니다.',
				reset_two_step_verification_success: '{{name}}의 2단계 인증이 재설정되었습니다.',
				reset_two_step_verification_title: '2단계 인증 재설정',
				user_disabled_successfully: '{{name}}이(가) 사용되지 않도록 설정되었습니다.',
				user_enabled_successfully: '{{name}}이(가) 사용되도록 설정되었습니다.',
			},
			folder_access: {
				delete_button: '삭제',
				delete_from_all_folders: '모든 폴더에서 제거',
				delete_from_folders_successfully: '폴더에서 {{name}}이(가) 삭제되었습니다.',
				delete_from_my_folders: '폴더에서 제거',
				delete_from_my_folders_message:
					'이 사용자를 폴더에서 삭제하면 프로세스를 되돌릴 수 없습니다.',
				description: '폴더에 있는 민감한 콘텐츠에 대한 이 사용자의 액세스 제한',
				header: '폴더 액세스',
			},
		},
	},
	{
		key: 'nl',
		content: {
			account_access: {
				description:
					'Beheer de toegang van deze gebruiker als u denkt dat zijn of haar account mogelijk is gehackt',
				disable_button: 'Uitschakelen',
				disable_user: 'Toegang uitschakelen',
				disable_user_message: 'Weet u zeker dat u deze gebruiker wilt uitschakelen?',
				email_password: 'E-mail voor wachtwoordherstel verzenden',
				email_password_link_text: 'Wachtwoord opnieuw instellen',
				email_password_message:
					'Hiermee wordt een e-mail naar {{email}} gestuurd met een koppeling om het wachtwoord opnieuw in te stellen. De koppeling verloopt over 30 minuten.',
				email_sent_toast:
					'E-mail om het wachtwoord opnieuw in te stellen is verzonden naar {{email}}',
				enable_user: 'Toegang inschakelen',
				header: 'Toegang tot account',
				log_user_out: 'Deze gebruiker afmelden',
				log_user_out_button: 'Afmelden',
				log_user_out_message: 'Weet u zeker dat u deze gebruiker wilt afmelden?',
				logged_out_successfully: '{{name}} is afgemeld',
				reset: 'Opnieuw instellen',
				reset_two_step_verification: 'Verificatie in twee stappen opnieuw instellen',
				reset_two_step_verification_error:
					'Kan verificatie in twee stappen niet opnieuw instellen voor {{name}}',
				reset_two_step_verification_message:
					'Weet u zeker dat u verificatie in twee stappen voor deze gebruiker opnieuw wilt instellen? Er wordt dan een e-mail naar de gebruiker verzonden waarin deze wordt vereist zijn/haar telefoonnummer opnieuw in te stellen voordat hij/zij zich kan aanmelden.',
				reset_two_step_verification_success:
					'Verificatie in twee stappen is opnieuw ingesteld voor {{name}}',
				reset_two_step_verification_title:
					'Verificatie in twee stappen opnieuw instellen',
				user_disabled_successfully: '{{name}} is uitgeschakeld',
				user_enabled_successfully: '{{name}} is ingeschakeld',
			},
			folder_access: {
				delete_button: 'Verwijderen',
				delete_from_all_folders: 'Uit alle mappen verwijderen',
				delete_from_folders_successfully: '{{name}} is uit de mappen verwijderd',
				delete_from_my_folders: 'Uit uw mappen verwijderen',
				delete_from_my_folders_message:
					'Als u deze gebruiker uit de mappen verwijdert, kan het proces kan niet worden teruggedraaid.',
				description:
					'De toegang van deze gebruiker tot gevoelige inhoud in mappen beperken',
				header: 'Toegang tot mappen',
			},
		},
	},
	{
		key: 'pt-BR',
		content: {
			account_access: {
				description:
					'Gerencie o acesso desse usuário se achar que sua conta pode ter sido comprometida',
				disable_button: 'Desativar',
				disable_user: 'Desativar o acesso',
				disable_user_message: 'Tem certeza de que deseja desativar este usuário?',
				email_password: 'Enviar email de redefinição de senha',
				email_password_link_text: 'Redefinir Senha',
				email_password_message:
					'Isso enviará um email para {{email}} com um link para redefinir sua senha. O link expira em 30 minutos.',
				email_sent_toast:
					'O email de redefinição de senha foi enviado com sucesso para {{email}}',
				enable_user: 'Ativar o acesso',
				header: 'Acesso à conta',
				log_user_out: 'Fazer logout deste usuário',
				log_user_out_button: 'Logout',
				log_user_out_message: 'Tem certeza de que deseja fazer o logout deste usuário?',
				logged_out_successfully: '{{name}} fez logout com sucesso',
				reset: 'Redefinir',
				reset_two_step_verification: 'Redefinir a verificação em duas etapas',
				reset_two_step_verification_error:
					'Não é possível redefinir a verificação de duas etapas para {{name}}',
				reset_two_step_verification_message:
					'Tem certeza de que deseja redefinir a verificação em dois passos deste usuário? Isso enviará um e-mail para o usuário e exigirá que ele redefina seu número de telefone antes que possa fazer logon.',
				reset_two_step_verification_success:
					'A verificação em duas etapas foi redefinida para {{name}}',
				reset_two_step_verification_title: 'Redefinir a verificação em duas etapas',
				user_disabled_successfully: '{{name}} desativado com sucesso',
				user_enabled_successfully: '{{name}} ativado com sucesso',
			},
			folder_access: {
				delete_button: 'Excluir',
				delete_from_all_folders: 'Remover de todas as pastas',
				delete_from_folders_successfully: '{{name}} excluído das pastas com sucesso',
				delete_from_my_folders: 'Remover das suas pastas',
				delete_from_my_folders_message:
					'Se você excluir este usuário das pastas o processo não poderá ser desfeito.',
				description: 'Limitar o acesso desse usuário a conteúdo confidencial nas pastas',
				header: 'Acesso a pastas',
			},
		},
	},
	{
		key: 'ru',
		content: {
			account_access: {
				description:
					'Управляйте доступом этого пользователя, если вы считаете, что его учетная запись могла быть взломана',
				disable_button: 'Отключить',
				disable_user: 'Отключить доступ',
				disable_user_message: 'Вы действительно хотите отключить этого пользователя?',
				email_password: 'Отправить письмо для сброса пароля',
				email_password_link_text: 'Сбросить пароль',
				email_password_message:
					'Вы получите электронное письмо {{email}} со ссылкой для сброса пароля. Срок действия ссылки истекает через 30 минут.',
				email_sent_toast:
					'Сообщение электронной почты для сброса пароля отправлено на адрес {{email}}',
				enable_user: 'Включить доступ',
				header: 'Доступ к учетной записи',
				log_user_out: 'Отключить этого пользователя',
				log_user_out_button: 'Выход',
				log_user_out_message: 'Действительно отключить этого пользователя?',
				logged_out_successfully: 'Пользователь {{name}} выполнил выход',
				reset: 'Сброс',
				reset_two_step_verification: 'Сброс двухэтапной проверки',
				reset_two_step_verification_error:
					'Не удалось сбросить двухэтапную проверку для {{name}}',
				reset_two_step_verification_message:
					'Действительно сбросить двухэтапную проверку для этого пользователя? В таком случае пользователю будет отправлено сообщение с просьбой сбросить номер телефона перед входом в систему.',
				reset_two_step_verification_success: 'Двухэтапная проверка сброшена для {{name}}',
				reset_two_step_verification_title: 'Сброс двухэтапной проверки',
				user_disabled_successfully: 'Пользователь {{name}} отключен',
				user_enabled_successfully: 'Пользователь {{name}} включен',
			},
			folder_access: {
				delete_button: 'Удалить',
				delete_from_all_folders: 'Удалить из всех папок',
				delete_from_folders_successfully: 'Элемент {{name}} удален из папок',
				delete_from_my_folders: 'Удалить из своих папок',
				delete_from_my_folders_message:
					'Удаление пользователя из папки является необратимым действием.',
				description:
					'Ограничьте доступ этого пользователя к конфиденциальному содержимому в папках',
				header: 'Доступ к папкам',
			},
		},
	},
	{
		key: 'zh-CN',
		content: {
			account_access: {
				description: '如果您认为此用户的帐户可能已被盗用，请管理此用户的访问权限',
				disable_button: '禁用',
				disable_user: '禁用访问权限',
				disable_user_message: '是否确实要禁用此用户?',
				email_password: '发送密码重置电子邮件',
				email_password_link_text: '重置密码',
				email_password_message:
					'这将向 {{email}} 发送一封电子邮件，其中包含用于重置其密码的链接。该链接将在 30 分钟后过期。',
				email_sent_toast: '重置密码电子邮件已成功发送到 {{email}}',
				enable_user: '启用访问权限',
				header: '帐户访问权限',
				log_user_out: '注销此用户',
				log_user_out_button: '注销',
				log_user_out_message: '是否确实要注销此用户?',
				logged_out_successfully: '已成功注销 {{name}}',
				reset: '重置',
				reset_two_step_verification: '重置双重验证',
				reset_two_step_verification_error: '无法为 {{name}} 重置双重验证',
				reset_two_step_verification_message:
					'是否确实要重置此用户的双重验证? 这将向用户发送一封电子邮件，并要求用户在登录之前重置其电话号码。',
				reset_two_step_verification_success: '为 {{name}} 重置了双重验证',
				reset_two_step_verification_title: '重置双重验证',
				user_disabled_successfully: '已成功禁用 {{name}}',
				user_enabled_successfully: '已成功启用 {{name}}',
			},
			folder_access: {
				delete_button: '删除',
				delete_from_all_folders: '从所有文件夹中删除',
				delete_from_folders_successfully: '已成功从文件夹中删除 {{name}}',
				delete_from_my_folders: '从您的文件夹中删除',
				delete_from_my_folders_message: '如果从文件夹中删除此用户，此过程将无法撤消。',
				description: '限制此用户对文件夹中的敏感内容的访问权限',
				header: '文件夹访问权限',
			},
		},
	},
];
