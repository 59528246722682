import React from 'react';
import { confirmModal, notifySuccess } from '@citrite/citrix-ui';
import { User, UsersEntity } from '@citrite/sf-api';
import { IAction } from '../Actions/IAction';
import { t } from '../utils';

export interface ResetPasswordProps extends IAction {
	currentUser: User;
	currentUserHasAdminRole: boolean;
}

export const ResetPassword = (props: ResetPasswordProps) => {
	if (!props.show) {
		return null;
	}

	const emailResetPassword = () => {
		const email = props.managedUser.Email;
		return confirmModal({
			title: t('user-actions:account_access.email_password'),
			message: t('user-actions:account_access.email_password_message', { email }),
			confirmText: 'Send',
			onCloseSuccess: () =>
				UsersEntity.forgotPassword(
					email || '',
					false,
					!!(
						props.currentUserHasAdminRole && props.currentUser.Id !== props.managedUser.Id
					)
				)
					.execute()
					.then(() => {
						notifySuccess(t('user-actions:account_access.email_sent_toast', { email }));
					}),
		});
	};

	return (
		<div
			data-pendo-id="ResetPasswordActionsPilet"
			className="linkStyle"
			onClick={emailResetPassword}
		>
			{t('user-actions:account_access.email_password_link_text')}
		</div>
	);
};
