import React from 'react';
import { notifyError, notifySuccess, warningConfirmModal } from '@citrite/citrix-ui';
import { TwoFactorAuthEntity } from '@citrite/sf-api';
import { IAction } from '../Actions/IAction';
import { t } from '../utils';

export const ResetTwoStepVerification = (props: IAction) => {
	if (!props.show) {
		return null;
	}

	const resetTwoStepVerification = () => {
		const user = props.managedUser;
		warningConfirmModal({
			title: t('user-actions:account_access.reset_two_step_verification_title'),
			message: t('user-actions:account_access.reset_two_step_verification_message'),
			confirmButtonText: t('user-actions:account_access.reset'),
			onCloseSuccess: () =>
				TwoFactorAuthEntity.sendResetNotification(user.Id || '')
					.execute()
					.then(() => {
						notifySuccess(
							t('user-actions:account_access.reset_two_step_verification_success', {
								name: user.FullName,
							})
						);
					})
					.catch(() => {
						notifyError(
							t('user-actions:account_access.reset_two_step_verification_error', {
								name: user.FullName,
							})
						);
					}),
		});
	};

	return (
		<div
			data-pendo-id="ResetTwoStepVerificationActionsPilet"
			className="linkStyle"
			onClick={resetTwoStepVerification}
		>
			{t('user-actions:account_access.reset_two_step_verification')}
		</div>
	);
};
