import React from 'react';
import { notifyError, notifySuccess, warningConfirmModal } from '@citrite/citrix-ui';
import { User, UsersEntity } from '@citrite/sf-api';
import { IAction } from '../Actions/IAction';
import { t } from '../utils';
import { tryExtractErrorMessage } from '../utils/javascript/messaging';

export function RemoveFromAllFolders(props: IAction) {
	if (!props.show) {
		return null;
	}

	return (
		<div
			data-pendo-id="RemoveFromAllFoldersActionsPilet"
			className="linkStyle"
			onClick={() => removeFromAllFolders(props.managedUser)}
			data-testid="removeAllFolders"
		>
			{t('user-actions:folder_access.delete_from_all_folders')}
		</div>
	);
}

function removeFromAllFolders(user: User) {
	return warningConfirmModal({
		title: t('user-actions:folder_access.delete_from_all_folders'),
		message: t('user-actions:folder_access.delete_from_my_folders_message'),
		confirmButtonText: t('user-actions:folder_access.delete_button'),
		onCloseSuccess: () => {
			UsersEntity.delete_(user?.url || '', false)
				.execute()
				.then(() =>
					notifySuccess(
						t('user-actions:folder_access.delete_from_folders_successfully', {
							name: user.FullName,
						})
					)
				)
				.catch(response => notifyError(tryExtractErrorMessage(response)));
		},
	});
}
