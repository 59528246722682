import { FailedResponse } from '@citrite/http';
import { Api, ExceptionReason } from '@citrite/sf-api';
import { t } from '@citrite/translate';

export function tryExtractErrorMessage(
	jqXhr: FailedResponse | Api.FailedAPIResponse,
	defaultMessage?: string
): string {
	if (
		getExceptionReason(jqXhr as Api.FailedAPIResponse) !== ExceptionReason.Unspecified
	) {
		try {
			if (jqXhr.responseJSON.message.value) {
				return jqXhr.responseJSON.message.value;
			} else if (jqXhr.responseJSON.message) {
				return jqXhr.responseJSON.message;
			}
		} catch (e) {}

		try {
			return JSON.parse(jqXhr.responseText).message.value;
		} catch (e) {}

		try {
			if (jqXhr.responseJSON.Message) {
				return jqXhr.responseJSON.Message;
			}
		} catch (e) {}
	}
	return defaultMessage || t('javascript:generic_error');
}

export function getExceptionReason(response: Api.FailedAPIResponse): ExceptionReason {
	try {
		const reason: string = response.responseJSON.reason;
		return ExceptionReason[reason as keyof typeof ExceptionReason];
	} catch (e) {}

	return ExceptionReason.Unspecified;
}
