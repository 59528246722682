import { css } from '@emotion/css';

export const actionStyles = css`
	border: 1px solid #d9d9d9;
	border-radius: 8px;
	padding: 16px 12px;
	margin: 16px 0;

	:first-child {
		margin-top: 0;
	}

	:last-child {
		margin-bottom: 0;
	}

	.sectionHeader {
		font-weight: 600;
		font-size: 16px;
		line-height: 18px;
		margin-bottom: 12px;
	}

	.sectionDescription {
		font-size: 14px;
		font-weight: 400;
		line-height: 16px;
		color: #767676;
		margin-bottom: 16px;
	}

	.linkStyle {
		color: #0045db;
		font-size: 14px;
		line-height: 16px;
		font-weight: 400;
		cursor: pointer;
		margin: 12px 0;
	}
`;
