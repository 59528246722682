import * as React from 'react';
import { Api } from '@citrite/sf-api';
import type { PiletApi } from '@sharefiledev/sharefile-appshell';
import { Actions } from './Actions/Actions';

export function setup(app: PiletApi) {
	app.sf.registerSfApiConfigHandler(Api.configure);

	app.registerExtension('urn:sfextslot:sharefile-appshell:user-actions', ({ params }) => (
		<Actions
			{...{
				currentUser: params.currentUser,
				managedUser: params.managedUser,
				accountPreferences: params.accountPreferences,
				currentUserHasAdminRole: params.currentUserHasAdminRole,
				canAdminResetPassword: params.canAdminResetPassword,
				employeesRoute: params.employeesRoute,
				clientsRoute: params.clientsRoute,
			}}
		/>
	));
}
