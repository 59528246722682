import React from 'react';
import { UserRole } from '@citrite/sf-api';
import { cx } from '@emotion/css';
import { ActionsProps } from '../Actions/Actions';
import { actionStyles } from '../Actions/styled';
import { t } from '../utils/translate';
import { RemoveFromAllFolders } from './RemoveFromAllFolders';
import { RemoveFromMyFolders } from './RemoveFromMyFolders';

export const FolderAccessActions = (props: ActionsProps) => {
	const canManageEmployees = props.currentUser.Roles?.includes(
		UserRole.AdminManageEmployees
	);
	const isCurrentUser = props.currentUser.Id === props.managedUser.Id;
	const isManagedUserEmployee = props.managedUser.Roles?.includes(UserRole.Employee);

	return (
		<div className={cx(actionStyles)} data-testid="folderAccessActions">
			<div className="sectionHeader">{t('user-actions:folder_access.header')}</div>
			<div className="sectionDescrption">
				{t('user-actions:folder_access.description')}
			</div>
			<RemoveFromMyFolders managedUser={props.managedUser} show={!isCurrentUser} />
			<RemoveFromAllFolders
				managedUser={props.managedUser}
				show={canManageEmployees && !isManagedUserEmployee}
			/>
		</div>
	);
};
