import React from 'react';
import { AccountPreferences, User, UserRole } from '@citrite/sf-api';
import { AccountAccessActions } from '../AccountAccessActions/AccountAccessActions';
import { FolderAccessActions } from '../FolderAccessActions/FolderAccessActions';

export interface ActionsProps {
	currentUser: User;
	managedUser: User;
	accountPreferences: AccountPreferences;
	currentUserHasAdminRole: boolean;
	canAdminResetPassword: boolean;
	employeesRoute: string;
	clientsRoute: string;
}

export const Actions = (props: ActionsProps) => {
	const isManagedUserAdmin = props.managedUser.Roles?.includes(UserRole.MasterAdmin);

	return (
		<>
			{!isManagedUserAdmin && (
				<>
					<AccountAccessActions {...props} />
					<FolderAccessActions {...props} />
				</>
			)}
		</>
	);
};
